import styles from "./Header.module.scss";
import ok from "../../assets/img/icons/ok.svg";

export const Header = () => {
    return (
        <div className={styles.content}>
            <div className={styles.title}>
                <div>Mami</div>
                <img src={ok} alt="" />
            </div>
            <div className={styles.menu}>
                <div>How It Works</div>
                <div>Recipes</div>
                <div>App</div>
                <div className={styles.btn}>Learn More</div>
            </div>
        </div>
    )
}