import { Route, Routes } from "react-router-dom";
import { MainPage } from "../../pages/MainPage/MainPage";
import { Header } from "../../components/Header/Header";

export function AppRoutes() {

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <>
                        <Header />
                        <MainPage />
                    </>
                }
            />
        </Routes>
    );
}