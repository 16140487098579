import styles from "./HealtyMenu.module.scss";
import dinner from "../../assets/img/icons/dinner.svg";
import fish from "../../assets/img/icons/fish.svg";
import pepper from "../../assets/img/icons/pepper.svg";
import food from "../../assets/img/food.svg";
import time from "../../assets/img/icons/time.svg";
import serving from "../../assets/img/icons/serving.svg";
import slider from "../../assets/img/icons/slider.svg";

export const HealthyMenu = () => {
	return (
		<div className={styles.content}>
			<div className={styles.titleBlock}>
				<div>POWERED BY ARTIFICIAL INTELLIGENCE</div>
				<div>Create Your Own Healthy Menu In a Minute with Mami</div>
				<div>
					Mami app will create a customized menu for you and your family based
					on your preferences, parameters and allergies.
				</div>
				<div className={styles.button}>Learn More</div>
			</div>
			<div className={styles.dinner}>
				<img src={food} alt="" />
                <img className={styles.slider} src={slider} alt="" />
				<div className={styles.block}>
					<div>Fried salmon with vegetables and garlic butter</div>
					<div>Dinner</div>
					<div>350 calories</div>
					<div className={styles.icons}>
						<img src={fish} alt="" />
						<img src={pepper} alt="" />
						<img src={dinner} alt="" />
					</div>
					<div className={styles.bottom}>
						<div className={styles.row}>
							<img src={time} alt="" />
							<div>25 min</div>
						</div>
						<div className={styles.row}>
							<img src={serving} alt="" />
							<div>1 serving</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
