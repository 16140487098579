import { BrowserRouter } from 'react-router-dom';
import styles from './App.module.scss';
import { AppRoutes } from './routes/AppRoutes';

function App() {
  return (
    <div className={styles.app}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
