import { FoodChoices } from "../../components/FoodChoices/FoodChoices";
import { HealthyMenu } from "../../components/HealtyMenu/HealthyMenu";
import styles from "./MainPage.module.scss";

export const MainPage = () => {
    return (
        <div className={styles.content}>
            <HealthyMenu />
            <FoodChoices />
        </div>
    )
}