import styles from "./FoodChoices.module.scss";
import mother from "../../assets/img/person1.svg";
import father from "../../assets/img/person2.png";
import daughter from "../../assets/img/person3.png";
import vegan from "../../assets/img/icons/vegan.svg";

export const FoodChoices = () => {
	return (
		<div className={styles.content}>
			<div className={styles.family}>
				<div className={styles.title}>Álvarez Family</div>
                <div className={styles.members}>
                    <div className={styles.member}>
                        <img src={mother} alt="" />
                        <div>5’5</div>
                        <div>110 lbs</div>
                        <div>Mina</div>
                        <div>29 y/o</div>
                        <img src={vegan} alt="" />
                    </div>
                </div>
			</div>
			<div className={styles.foodChoices}></div>
		</div>
	);
};
